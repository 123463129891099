<template>
  <div class="mx-auto">
    <div class="my-wrapper w-full mx-auto">
      <div v-if="loading">
        <parula-loading />
      </div>
      <div v-else class="flex flex-col items-center w-full">
        <div class="content__layout">
          <content-viewer :filePath="productInfo.image" />
        </div>
        <div class="content__layout mt-5 m-5 md:m-auto md:m-0">
          <div class="text-sm md:text-base font-semibold">INFORMATION</div>
          <div class="flex w-full">
            <div
              class="
                grid grid-cols-1
                gap-x-2 gap-y-1
                place-content-between
                font-semibold
                mt-3
              "
            >
              <div class="text-parula-dark mb-1">
                Title
                <span class="text-parula-gray-1 font-medium ml-3">
                  {{ productInfo.name }}
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Owners
                <span class="text-parula-gray-1 font-medium ml-3">
                  {{ productInfo.owners ? productInfo.owners.length : 0 }}
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Mint At
                <span class="text-parula-gray-1 font-medium ml-3">
                  {{
                    String(new Date(productInfo.created_at)).substring(0, 15)
                  }}
                </span>
              </div>
            </div>
          </div>

          <hr class="block w-full mb-3 border-t-26 my-3" />
          <div class="text-sm md:text-base font-semibold">BLOCKCHAIN INFO</div>
          <div class="flex w-full">
            <div
              class="
                grid grid-cols-1
                gap-x-2 gap-y-1
                place-content-between
                font-semibold
                mt-3
              "
            >
              <div class="text-parula-dark mb-1">
                Blockchain
                <span class="text-parula-gray-1 font-medium ml-3">
                  <template v-if="productInfo.blockchain_id == 1">
                    Ethereum
                  </template>
                  <template v-if="productInfo.blockchain_id == 3">
                    Ethereum Ropsten
                  </template>
                  <template v-if="productInfo.blockchain_id == 4">
                    Ethereum Rinkeby
                  </template>
                  <template v-if="productInfo.blockchain_id == 1001">
                    Klaytn Baobab
                  </template>
                  <template v-if="productInfo.blockchain_id == 8217">
                    Klaytn
                  </template>
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Contract
                <span class="text-parula-gray-1 parula__text-blue font-medium ml-3">
                  <template v-if="productInfo.blockchain_id == 1">
                    <a :href="`https://etherscan.io/token/${productInfo.asset_address}`" target="_blank">
                      {{ productInfo.asset_address.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 3">
                    <a :href="`https://ropsten.etherscan.io/token/${productInfo.asset_address}`" target="_blank">
                      {{productInfo.asset_address.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 4">
                    <a :href="`https://rinkeby.etherscan.io/token/${productInfo.asset_address}`" target="_blank">
                      {{productInfo.asset_address.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 1001">
                    <a :href="`https://baobab.scope.klaytn.com/nft/${productInfo.asset_address}?tabId=nftTransfer`" target="_blank">
                      {{productInfo.asset_address.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 8217">
                    <a :href="`https://scope.klaytn.com/nft/${productInfo.asset_address}?tabId=nftTransfer`" target="_blank">
                      {{productInfo.asset_address.slice(0, 15) + "..."}}
                    </a>
                  </template>
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Type
                <span class="text-parula-gray-1 font-medium ml-3">
                  {{ productInfo.asset_type }}
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Token
                <span class="text-parula-gray-1 font-medium ml-3">
                  <!-- {{ productInfo.asset_symbol }}[{{productInfo.token_id}}] -->
                  {{ `${productInfo.asset_symbol}[${productInfo.token_id}]` }}
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Supply
                <span class="text-parula-gray-1 font-medium ml-3">
                  {{productInfo.total_supply }}
                </span>
              </div>
              <div class="text-parula-dark mb-1">
                Txn Hash
                <span class="text-parula-gray-1 parula__text-blue font-medium ml-3">
                  <template v-if="productInfo.blockchain_id == 1">
                    <a :href="`https://etherscan.io/tx/${productInfo.transaction_hash}`" target="_blank">
                      {{productInfo.transaction_hash.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 3">
                    <a :href="`https://ropsten.etherscan.io/tx/${productInfo.transaction_hash}`" target="_blank">
                      {{productInfo.transaction_hash.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 4">
                    <a :href="`https://rinkeby.etherscan.io/tx/${productInfo.transaction_hash}`" target="_blank">
                      {{productInfo.transaction_hash.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 1001">
                    <a :href="`https://baobab.scope.klaytn.com/tx/${productInfo.transaction_hash}`" target="_blank">
                      {{productInfo.transaction_hash.slice(0, 15) + "..."}}
                    </a>
                  </template>
                  <template v-if="productInfo.blockchain_id == 8217">
                    <a :href="`https://scope.klaytn.com/tx/${productInfo.transaction_hash}`" target="_blank">
                      {{productInfo.transaction_hash.slice(0, 15) + "..."}}
                    </a>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// <div class="flex min-h-screen flex-wrap">
//     <div
//       class="
//         flex-shrink-0
//         w-full
//         h-auto
//         md:w-full md:w-11/12
//         lg:w-10/12
//         xl:w-9/12
//         2xl:w-8/12
//         mx-auto
//       "
//     >
//       <div class="my-wrapper 2xl:w-1440px w-full h-full relative">
//         <div v-if="loading" class="font-inter mt-10 text-center">
//           <p>Please wait...</p>
//         </div>

//         <div v-else>
//           <!-- Contents Start -->
//           <section class="md:flex justify-between mt-10 md:mt-24">
//             <div class="flex justify-center w-full md:w-3/5">
//               <content-viewer
//                 class="w-full h-full"
//                 :filePath="productInfo.image"
//               />
//               <!-- <img :src="productInfo.image" class="h-80" /> -->
//             </div>

// <div class="mt-5 m-5 md:m-auto md:m-0">
//   <div
//     class="text-sm md:text-base font-semibold text-parula-gray-1"
//   >
//     INFORMATION
//   </div>
//   <div class="flex w-full">
//     <div
//       class="
//         grid grid-cols-1
//         gap-x-2 gap-y-1
//         place-content-between
//         font-semibold
//         mt-3
//       "
//     >
//       <div class="text-parula-dark mb-1">
//         Title
//         <span class="text-parula-gray-1 font-medium ml-3">
//           {{ productInfo.name }}
//         </span>
//       </div>
//       <div class="text-parula-dark mb-1">
//         Owners
//         <span class="text-parula-gray-1 font-medium ml-3">
//           {{ productInfo.owners ? productInfo.owners.length : 0 }}
//         </span>
//       </div>
//       <div class="text-parula-dark mb-1">
//         Mint At
//         <span class="text-parula-gray-1 font-medium ml-3">
//           {{
//             String(new Date(productInfo.created_at)).substring(
//               0,
//               15
//             )
//           }}
//         </span>
//       </div>
//     </div>
//   </div>

//   <hr class="block w-full mb-3 border-t-26 my-3" />
//   <div
//     class="text-sm md:text-base font-semibold text-parula-gray-1"
//   >
//     BLOCKCHAIN INFO
//   </div>
//   <div class="flex w-full">
//     <div
//       class="
//         grid grid-cols-1
//         gap-x-2 gap-y-1
//         place-content-between
//         font-semibold
//         mt-3
//       "
//     >
//       <div class="text-parula-dark mb-1">
//         Type
//         <span class="text-parula-gray-1 font-medium ml-3">
//           {{ productInfo.asset_type }}
//         </span>
//       </div>
//       <div class="text-parula-dark mb-1">
//         Symbol
//         <span class="text-parula-gray-1 font-medium ml-3">
//           {{ productInfo.asset_symbol }}
//         </span>
//       </div>
//     </div>
//   </div>
// </div>
//           </section>
//           <!-- Contents End -->
//         </div>
//       </div>
//     </div>
//   </div>

import ContentViewer from "@/components/ContentViewer.vue";
import OpenapiService from "@/services/openapi.service";
import ParulaLoading from "@/components/ParulaLoading.vue";
// import Creator from "./sub/Creator.vue";
// import Artworks from "./sub/Artworks.vue";
// import SubHistory from "./sub/SubHistory.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    ContentViewer,
    ParulaLoading,
    // Creator,
    // Artworks,
    // SubHistory,
  },

  data: function () {
    return {
      loading: true,
      mode: "history",

      productInfo: {},
      historyInfo: [],
    };
  },

  created() {
    const path = this.$route.path.split("/");
    const product_id = path[2];

    this.loadProductDetail(product_id);
  },

  methods: {
    loadProductDetail(product_id) {
      OpenapiService.productGet(product_id)
        .then((res) => {
          this.productInfo = res.data;
          console.log("this.productInfo ==> ", this.productInfo)
          const path = this.$route.path.split("/");
          const product_id = path[2];

          this.loadHistory(product_id);
          this.loading = false;
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },

    loadHistory(content_id) {
      OpenapiService.productHistory(content_id)
        .then((res) => {
          this.historyInfo = res.data;
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },
  },
};
</script>

<style scoped>
.content__layout {
  margin: 10vh 0px 0px 0px;
  width: 60vmax;
  height: 60vmax;
}
</style>
